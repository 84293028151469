import React, { useEffect, useState } from 'react'

function Pagedesign() {
    const [text, setText] = useState('Mikeyas Derje');

    useEffect(() => {
        setTimeout(() => {
           setText('Developer') 
        },2000)
    })
    return (
      <>
      <div className= 'container' >
        <div className='top' >
        <div className='top_Left' >
            <p>Mk</p>
            </div>
        <div className = 'top_Right' >
            <img src='images/mine_image.jpg'/>
                </div>
            </div>
        <div className = 'middle' >
            <div className="introText">
        <p>I am {text}</p>
            </div>
        <div className = 'Aboutme' >
         <p>I am a passionate full-stack web and mobile app developer with 3 years of experience<br/> in creating dynamic and responsive applications.<br/> My expertise spans across HTML, CSS, JavaScript, TypeScript, React,React Native, and Nodejs.<br/>
Throughout my career ,I've successfully delivered numerous projects,<br/> transforming ideas into functional and aesthetically pleasing digital solutions.<br/> Whether it's developing a complex web application or a sleek mobile app,<br/> I strive to build products that provide excellent user experiences.</p>
    </div>
        <div className = 'button' >
         <button>CV</button>
            </div>
             <div className = 'bottom' >
            <div className='small_containers'><p>HTML|CSS|JAVASCRIPT</p><div className='percent-con'><div className='percent'><div className='percent-bar' style={{'--percent':'95%'} as any}></div></div></div><p>95%</p></div>
            <div className='small_containers'><p>REACT|REACTNATIVE</p><div className='percent-con'><div className='percent'><div className='percent-bar' style={{'--percent':'90%'} as any}></div></div></div><p>90%</p></div>
            <div className='small_containers'><p>TYPESCRIPT</p><div className='percent-con'><div className='percent'><div className='percent-bar' style={{'--percent':'90%'} as any}></div></div></div><p>90%</p></div>
            <div className='small_containers'><p>REDUX</p><div className='percent-con'><div className='percent'><div className='percent-bar' style={{'--percent':'70%'} as any}></div></div></div><p>70%</p></div>
            <div className='small_containers'><p>NODEJS</p><div className='percent-con'><div className='percent'><div className='percent-bar' style={{'--percent':'80%'} as any}></div></div></div><p>80%</p></div>
                </div>
            </div>
    </div>
       
    
    </>
  )
}

export default Pagedesign
